<template>
  <label for="toggle" class="flex flex-col flex-wrap items-center cursor-pointer md:mb-0">
    <div class="relative">
      <input id="toggle" type="checkbox" class="hidden" @change="switchColorMode()">
      <div
          @click="$colorMode.preference = 'light'"
          class="dark:text-primary-dark hover:text-black"
          :class="{'visible': isDark, 'hidden': !isDark}"
      >
        <IconsSun />
      </div>
      <div
          @click="$colorMode.preference = 'dark'"
          class="dark:text-primary-dark hover:text-black"
          :class="{'visible': !isDark, 'hidden': isDark}"
      >
        <IconsMoon />
      </div>
    </div>
  </label>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useSettings } from '@/stores/settings'

export default {
  computed: {
    ...mapState(useSettings, ['colorMode']),
    isDark () {
      return this.colorMode.value === 'light'
    },
  },
  methods: {
    ...mapActions(useSettings, ['switchColorMode']),
  },
}
</script>