<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-menu icon"
  >
    <line x1="3" y1="12" x2="21" y2="12"></line>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <line x1="3" y1="18" x2="21" y2="18"></line>
  </svg>
</template>

<style scoped>
.icon {
  @apply
  bg-primary-light
  dark:bg-primary-dark
  text-white
  dark:text-salat-text
  rounded-full
  w-10
  h-10
  p-2
  ml-3
  mb-2
}
</style>