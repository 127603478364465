<template>
  <footer class="footer-wrapper">
    <!--      <div class="bg-primary py-9 ">-->
    <!--        <div class="flex flex-col lg:px-24 md:flex-row justify-between items-center max-w-7xl mx-auto px-5 lg:px-10">-->
    <!--          <span class="text-white text-center">Хочешь выставить свои работы на продажу в Артели? Жми кнопульку!</span>-->
    <!--          <button -->
    <!--            @click="()=>{ auth ? showModal = true : useRouter().push('/registration') }"-->
    <!--            class="upload-work-button"-->
    <!--          >-->
    <!--            Хочу!-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <div class="bottom-section">
      <div class="flex flex-col items-center md:items-stretch">
        <!--          <span class="text-lg font-bold mb-2.5 cursor-pointer">+7 (812) 566-66-50</span>-->
        <!--          <a class="font-light cursor-pointer hover:text-black">info@artel.gallery</a>-->
      </div>
      <div v-if="auth" class="flex flex-col text-center mt-8 space-y-5 md:space-y-0 md:mt-0 md:flex-row">
        <NuxtLink class="menu-item" to="/gallery">Галерея</NuxtLink>
        <NuxtLink class="menu-item" to="/authors">Участники</NuxtLink>
        <NuxtLink class="menu-item" to="/open-calls">Мероприятия</NuxtLink>
        <NuxtLink class="menu-item" to="/about">О нас</NuxtLink>
        <NuxtLink class="menu-item" to="/contacts">Контакты</NuxtLink>
      </div>
    </div>
    <div class="copyrights">
      <div class="text-sm">
        Артель © 2024 |
        <a href="//artel.gallery/docs/legal-papers.html">Правовые документы</a>
      </div>
      <div class="text-sm mt-5">Midyanets Design</div>
    </div>
  </footer>
  <BaseModal v-model:show="showModal" :textH3="'Загрузите свою работу и заполните обязательные поля'">
    <UiSlotOfTheModalFileUpload v-model:message="showMessageUpload" v-model:show="showModal" />
  </BaseModal>
  <BaseModal v-model:show="showMessageUpload" :textH3="'Работа успешно загружена'" />
</template>

<script setup>
import { useUserData } from '~/stores/userData';
import { storeToRefs } from 'pinia';
const store = useUserData();
const { authenticated } = storeToRefs(store);
const auth = authenticated;
const showModal = ref(false);
const showMessageUpload = ref(false);
</script>

<style scoped>
.footer-wrapper {
  @apply
  w-full
  mx-auto
  max-w-7xl
  border-t-[1px]
  border-black
  dark:border-gray02-20
}

.bottom-section {
  @apply
    flex
    flex-col
    items-center
    md:items-stretch
    md:flex-row
    2xl:px-10
    xl:px-0
    lg:px-10
    md:px-5
    justify-between
    max-w-7xl
    mx-auto
    py-12
    bg-gray-100
    dark:bg-gray-900
}

.menu-item {
  @apply
  pl-2
  text-sm
  font-light
  text-gray02-100
  dark:text-gray02-20
  hover:text-primary-light
}

.copyrights {
  @apply
    flex
    flex-col
    md:flex-row
    items-center
    justify-between
    max-w-7xl
    mx-auto
    xl:px-0
    lg:px-10
    md:px-5
    py-9
    font-light
    bg-gray-100
    dark:bg-gray-900
    text-gray02-80
    dark:text-gray02-60
}

.upload-work-button {
  @apply
  px-5
  py-2
  rounded-full
  text-primary-light
  mt-5
  md:mt-0
  bg-gray-200
  hover:bg-gray-50
}
</style>
