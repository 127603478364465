import { defineStore } from 'pinia'

export const useSettings = defineStore('settings', {
  state: () => ({
    colorMode: useColorMode(),
  }),

  actions: {
    switchColorMode () {
      const value = (this.colorMode.value == 'light') ? 'dark' : 'light'
      this.colorMode = {...this.colorMode, value}
    }
  },
})
