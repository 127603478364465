<template>
  <header class="header border-b-[1px] border-gray02-100 dark:border-gray02-20">
    <nav>
      <!-- desktop menu -->
      <div class="max-w-7xl mx-auto px-5">
        <div class="flex justify-between md:px-0 lg:px-5">
          <!-- logo -->
          <div class="logo">
            <NuxtLink to="/">Артель</NuxtLink>
          </div>

          <!-- menu -->
          <div class="flex">
            <div class="menu-items">
              <NuxtLink class="nav-link-desktop" to="/gallery">Галерея</NuxtLink>
              <NuxtLink class="nav-link-desktop" to="/authors">Участники</NuxtLink>
              <NuxtLink class="nav-link-desktop" to="/open-calls">Мероприятия</NuxtLink>
              <NuxtLink class="nav-link-desktop" to="/about">О нас</NuxtLink>
              <NuxtLink class="nav-link-desktop" to="/contacts">Контакты</NuxtLink>
            </div>

            <div class="flex items-center">
              <div class="flex space-x-2 items-center">
                <!-- right icons -->
                <!-- <NuxtLink
                    class="invisible md:visible py-5 text-primary hover:text-black"
                    to="/"
                    title="поиск"
                >
                  <IconsSearch />
                </NuxtLink> -->
                <NuxtLink to="/login" title="авторизация"
                  class="cursor-pointer text-gray02-80 dark:text-primary-dark hover:text-black p-3"
                  :class="{ hidden: auth }">
                  <IconsUser />
                </NuxtLink>
                <NuxtLink to="/dashboard" title="Личный кабинет" class="cursor-pointer py-5 text-primary hover:opacity-80"
                  :class="{ hidden: !auth }">
                  <BaseAvatar :image="avatar" :imageAlt="name" />
                </NuxtLink>
                <ColorModePicker />
              </div>

              <!-- button show mobile menu -->
              <button @click="showMobileMenu = !showMobileMenu" class="lg:hidden ml-5">
                <IconsBurgerOpen v-if="!showMobileMenu" />
                <IconsBurgerClose v-if="showMobileMenu" />
              </button>
            </div>
          </div>


        </div>
      </div>

      <!-- mobile menu -->
      <div class="mobile-menu" v-show="showMobileMenu">

        <!-- search field  -->
        <div class="py-2 px-4 pb-5">
          <input type="text" name="search" class="search-field" placeholder="Поиск">
        </div>

        <NuxtLink class="nav-link-mobile" @click="showMobileMenu = false" to="/gallery">Галерея</NuxtLink>
        <NuxtLink class="nav-link-mobile" @click="showMobileMenu = false" to="/authors">Участники</NuxtLink>
        <!-- <NuxtLink class="nav-link-mobile" @click="showMobileMenu = false" to="/open-calls">Мероприятия</NuxtLink> -->
        <NuxtLink class="nav-link-mobile" @click="showMobileMenu = false" to="/about">О нас</NuxtLink>
        <NuxtLink class="nav-link-mobile" @click="showMobileMenu = false" to="/contacts">Контакты</NuxtLink>
        <div class="mt-5 font-light">

          <!-- TODO: меню для авторизованного пользователя -->
          <div class="user-menu-container" role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
            tabindex="-1" v-show="true">
            <div class="px-4 py-3" role="none">
              <div>
                <span class="text-sm" role="none" v-if="false">Вход выполнен как</span>
                <span v-show="isActivated" class="text-sm font-light text-green-600" role="none">
                  Почта подтверждена
                </span>
                <span v-show="!isActivated && auth" class="text-sm font-light text-red-600" role="none">
                  Почта не подтверждена
                </span>
              </div>
              <span class="truncate text-sm font-thin text-gray-500" role="none">{{ email }}</span>
            </div>
            <div class="mx-4 my-5 space-y-5">
              <NuxtLink to="/dashboard" class="flex text-gray-500 dark:text-secondary-dark"
                @click="showMobileMenu = !showMobileMenu">
                <IconsDashboard />
                <span class="text-black hover:text-primary-light dark:text-primary-dark">Личный кабинет</span>
              </NuxtLink>
              <NuxtLink to="/dashboard/collection" class="flex text-gray-500 dark:text-secondary-dark"
                @click="showMobileMenu = !showMobileMenu">
                <IconsMyCollection />
                <span class="text-black hover:text-primary-light dark:text-primary-dark">Моя коллекция</span>
              </NuxtLink>
              <NuxtLink to="/dashboard/settings" class="flex text-gray-500 dark:text-secondary-dark"
                @click="showMobileMenu = !showMobileMenu">
                <IconsSettingsHeader />
                <span class="text-black hover:text-primary-light dark:text-primary-dark">Настройки</span>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { useUserData } from '~/stores/userData';
import { storeToRefs } from 'pinia';
const showMobileMenu = ref(false);
const store = useUserData();
const { userData } = storeToRefs(store);
const storage = ref(null);
const userLocal = ref(null);
const auth = ref(false);

if (process.client) {
  storage.value = localStorage.getItem('userData');
  if (storage.value) userLocal.value = JSON.parse(storage.value);
  if (userLocal.value && userLocal.value.authenticated) await store.getUser();
};
onMounted(() => { if (userLocal.value && userLocal.value.authenticated) auth.value = true });

const user = ref(userData);
const name = ref("");
const isActivated = ref(false);
const email = ref("");
const avatar = ref("");
const firstLetterOfName = ref("");
if (user.value.first_name) {
  name.value = `${user.value.first_name} ${user.value.last_name}`;
  isActivated.value = user.value.is_email_activated;
  email.value = user.value.email;
  avatar.value = user.value.avatar;
  firstLetterOfName.value = user.value.first_name[0];
}
</script>

<style>
.header {
  @apply
  bg-gray-100
  dark:bg-gray-900
}

.logo {
  @apply
  flex-none
  items-center
  space-x-2
  py-8
  text-primary-light
  dark:text-primary-dark
  text-2xl
  font-extrabold
}

.menu-items {
  @apply
  hidden
  lg:flex
  mr-20
}

.menu-items .router-link-exact-active {
  @apply
  border-b-2
  border-gray02-100
  dark:border-gray02-20
  text-primary-light
}

.nav-link-desktop {
  @apply
  px-2
  pt-10
  text-sm
  font-light
  focus:border-b-2
  border-black
  hover:text-primary-light
  text-gray02-100
  dark:text-gray02-20
}

.mobile-menu {
  @apply
  lg:hidden
  bg-gray-100
  dark:bg-gray-800
  ease-in
  duration-500
  border-t-2
  pt-5
  pb-40
  pl-2
  border-gray-500
}

.nav-link-mobile {
  @apply
  block
  py-2
  px-4
  text-primary-light
  dark:text-primary-dark
  text-lg
}

.search-field {
  @apply
  block
  w-full
  rounded-md
  border-gray-300
  pl-4
  py-2
  focus:border-indigo-500
  focus:ring-indigo-500
  sm:text-sm
}

.cart-counter {
  @apply
  absolute
  inline-flex
  items-center
  justify-center
  w-6
  h-6
  text-xs
  text-white
  font-thin
  bg-primary-light
  border-white
  rounded-full
  -top-3
  -right-4
}
</style>
